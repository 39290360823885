<template>
  <div class="shipperDetails_box">
    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">基本信息</div>
      </div>

      <div class="details_Style">
        <div class="details_content">
          <div class="details_title">姓名：</div>
          <el-input class="detailsInput" size="mini" v-model="detailsList.userName" :disabled="type == 'details' "></el-input>
        </div>
        <div class="details_content">
          <div class="details_title">手机号：</div>
          <el-input class="detailsInput" size="mini" v-model="detailsList.mobile" :disabled="type == 'details' " ></el-input>
        </div>
      </div>
    </div>

    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">照片</div>
      </div>

      <div class="image_upload">
        <div class="img_box">
          <el-image class="image" v-if="detailsList.idFrontImg" :src="detailsList.idFrontImg" :preview-src-list="[detailsList.idFrontImg]">
          </el-image>
          <div v-else class="no_picture">
            <div class="tips">暂无图片</div>
          </div>
          <div class="image_title">身份证正面</div>
        </div>

        <div class="img_box">
          <el-image class="image"  v-if="detailsList.idBackImg" :src="detailsList.idBackImg" :preview-src-list="[detailsList.idBackImg]">
          </el-image>
          <div v-else class="no_picture">
            <div class="tips">暂无图片</div>
          </div>
          <div class="image_title">身份证反面</div>
        </div>

        <!-- <div class="img_box">
          <el-image class="image"  v-if="detailsList.licenseImgUrl" :src="detailsList.licenseImgUrl" :preview-src-list="[detailsList.legalIdFrontImg]">
          </el-image>
          <div v-else class="no_picture">
            <div class="tips">暂无图片</div>
          </div>
          <div class="image_title">营业执照</div>
        </div> -->

      </div>
    </div>

    <div class="button_btn">
      <el-button type="reset" size="small" @click="goBack">返 回</el-button>
    </div>
  </div>
</template>
<script>
import { shipperDetail } from "@/api/basics";
// import utils from "@/plugin/utils/utils";
export default {
  data() {
    return {
      type: this.$route.query.type,
      detailsList: {},
    };
  },
  mounted() {
    // this.detailsList = list;
    // console.log(this.detailsList, " this.detailsList", this.type);
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let list = JSON.parse(this.$route.query.row);
      try {
        let data = {
          userBusinessId: list.userBusinessId,
        };
        let res = await shipperDetail(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          this.detailsList = res.rspBody;
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    goBack() {
      this.$router.push({
        path: "/shipper/shipper",
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.shipperDetails_box {
  width: 100%;
  height: 100%;
  position: relative;

  .image_upload {
    width: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img_box {
      padding-right: 15px;
      .image_title {
        padding-top: 10px;
      }

      .image {
        height: 178px;
        width: 178px;
      }
    }

    .no_picture {
      height: 178px;
      width: 178px;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;

      .tips {
        height: 178px;
        width: 178px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .button_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
  }
}
</style>